import React, {useEffect, useState} from 'react';
import {Row, Col, Typography, Slider, ColorPicker, List, Collapse, Radio, Tooltip} from 'antd';
import TextArea from "antd/es/input/TextArea";
import textForTemplate from "./textForTemplate";



function CardText({textStyle, setTextStyle,editorHtml, setEditorHtml, onUpdateText, onUpdateStyle}) {
  const { Text } = Typography;
  const { Panel } = Collapse;
  const fontClasses = [
    'Gabriela',
    'gabriola',
    'bernhardModern',
    'bickhamScriptPro',
    'nradleyHandeItc',
    'cathedral',
    'chopinScript',
    'nuptialScript',
    'sheerElegance',
    'penyaeLight',
    'phyllisAttItalic',
    'moderne',
    'wendyMedium',
    'wendysHand'
  ];

    const [colorHex, setColorHex] = useState('#000');
    const [formatHex, setFormatHex] = useState('hex');
  const [radioChecked, setRadioChecked] = useState(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  const hexString = React.useMemo(
      () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
      [colorHex],
    );

  const updateTextProperties = (property, value) => {
    if (property === 'content') {
      setEditorHtml(value);
      onUpdateText(value);
    } else {
        const newStyle = { ...textStyle, [property]: value };
        setTextStyle(newStyle);
        onUpdateStyle(newStyle);
      }
  };
  useEffect(() => {
      updateTextProperties ("color", hexString)
  }, [hexString]);

  const onSelectTemplate = (templateText) => {
    setEditorHtml(templateText);
    onUpdateText(templateText);

    setRadioChecked(true);
    setCollapseActiveKey([])
  };
  const textItemsArray = [
    {
      key: '1',
      header: 'Choose your text for card',
      content: (
        <Row>
          <Col className="mb10 text-center" >
            <List
              style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'grey #DCF8ED' }}
              dataSource={textForTemplate}
              renderItem={item => (
                <List.Item onClick={() => { onSelectTemplate(item.text); setRadioChecked(item.id); }}>
                  <Row>
                    <Col>
                      <Radio value={item.id} checked={radioChecked === item.id} style={{ marginRight: '5px' }} />
                      <span>Text: {item.id}</span>
                    </Col>
                    <Col>
                      <Typography.Text>{item.text}</Typography.Text>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )
    }
  ];

  return (
    <>
      <Collapse
        ghost
        accordion
        activeKey={collapseActiveKey}
        onChange={(keys) => setCollapseActiveKey(keys)}
        expandIconPosition="end"
        className="mb10"
      >
        {textItemsArray.map(item => (
          <Panel header={item.header} key={item.key} id="textPanel1" style={{ backgroundColor: '#DCF8ED' }}>
            {item.content}
          </Panel>
        ))}
      </Collapse>
      <hr />
      <Row>
        <Col lg={24} md={24} span={24} className="mb10">
          <TextArea
            className="stamping-input"
            type="text"
            value={editorHtml}
            onChange={(e) => updateTextProperties('content', e.target.value)}
            placeholder="Enter your text here"
            autoSize={true}
          />
        </Col>
        <hr />
        <Col  span={24} className="mb10 text-center" >
          <p className="album-sidebar-image-title p10-b">Font Size</p>
          <Slider
            className="stamping-font-size"
            defaultValue={24}
            min={10}
            max={66}
            onChange={(value) => updateTextProperties('fontSize', `${value}px`)}
          />
        </Col>
        <hr />
        <Col sm={8} span={12} className="mb10 text-center" >
          <p className="album-sidebar-image-title p10">Text color</p>
          <ColorPicker
            format={formatHex}
            value={colorHex}
            onChange={setColorHex}
            onFormatChange={setFormatHex}
          />
        </Col>
        <Col  sm={15} span={24} className="mb10 text-center" >
          <p className="album-sidebar-image-title p10-b">Font line height</p>
          <Slider
            className="stamping-font-size"
            defaultValue={24}
            min={10}
            max={66}
            onChange={(value) => updateTextProperties('lineHeight', `${value}px`)}
          />
        </Col>
        <hr />
        <Col sm={12} span={24} className="mb10 text-center">
          <p className="album-sidebar-image-title p10">Font Weight</p>
          <Row justify='space-evenly'>
            <span >
             <p onClick={() => updateTextProperties('fontWeight', '500')} style={{ fontWeight: '500', padding: '4px' }}>Normal</p>
            </span>
            <span>
              <p onClick={() => updateTextProperties('fontWeight', '700')} style={{ fontWeight: '700', padding: '4px' }}>Bold</p>
            </span>
          </Row>
        </Col>
        <Col sm={12} span={24} className="mb10 text-center" >
          <p className="album-sidebar-image-title p10">Font Style</p>
          <Row justify='space-evenly'>
            <span>
              <p onClick={() => updateTextProperties('fontStyle', 'normal')} style={{ fontStyle: 'normal', padding: '4px' }}>Normal</p>
            </span>
            <span>
              <p onClick={() => updateTextProperties('fontStyle', 'italic')}style={{ fontStyle: 'italic', padding: '4px' }}>Italic</p>
            </span>
          </Row>
        </Col>
        <hr />
        <Col  span={24} className="mb10 text-center" >
          <p className="album-sidebar-image-title p10">Font family</p>
          <Row>
            {fontClasses.map((font, index) => (
              <Col key={index} lg={6} md={6} span={12} className={`${font} stamping-font-family`}>
                <Tooltip title ={`${font}`}>
                <p
                  onClick={() => updateTextProperties('fontFamily', font)}
                  className={textStyle.fontFamily === font ? 'stamping-font-family-border' : ''}
                >
                  ABC
                </p>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}


export default CardText;