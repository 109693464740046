import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Space, Typography } from 'antd';
import images from './AlbumSidebarImages';
import {CloseCircleOutlined,
    BorderVerticleOutlined,
    BorderBottomOutlined,
    BorderRightOutlined,

} from '@ant-design/icons';

function AlbumTextSidebar({
        setSidebarOpen,
        setTextProperties,
        setIsStampingOpen

}) {
    const { Text } = Typography;
    const fontClasses = [
        'nunito',
        'gabriola',
        'raleway',
        'playfairDisplay',
        'roboto',
        'openSans',
        'ebGaramond',
        'montserrat',
        'libreBaskerville',
        'robotoCondensed',
        'oswald'
    ];


    console.log(fontClasses)
    
    const [textPos, setTextPos] = useState('CENTER');
    const [color, setColor] = useState("#ffd700");
    const [fontFamily, setFontFamily] = useState("nunito");
    const goBack = () => {
        setSidebarOpen(true);
        setIsStampingOpen(false);
    };

    const updateTextProperties = (property, value) => {
        setColor(value);
        setTextProperties(prev => ({ ...prev, [property]: value }));
    };

    const updateTextPosition = (property, value) => {
        setTextProperties(prevState => ({
            ...prevState,
            position: {
                ...prevState.position,
                [property]: value
            }
        }));
    }

    const updatePosition = (position) => {
        setTextPos(position);
        updateTextPosition("type", position);
    }

  const updateFontFamily = (font) => {
      setFontFamily(font);
      updateTextProperties("fontFamily", font);
    }


    return (
        <>
            <Row  gutter={16}>
                <Col lg={24} md={24} span={24}>
                <div onClick={goBack}><CloseCircleOutlined className="fs19"/> Back</div>
                </Col>
                    <Col lg={12} md={12} span={24}>
                        <img className="left-sidebar-covers p10" src={images[24].src} alt=""/>
                    </Col>

                    <Col lg={12} md={12} span={24}>
                        <p className="album-sidebar-image-title p10-b">Foil Stamping</p>
                        <p className="album-sidebar-image-desc p10-b">$20.00</p>
                        <p></p>
                    </Col>
                    <Col lg={12} md={12} span={24}>
                        <p className="album-sidebar-image-title p10-b">UV Album Stamping</p>
                        <p className="album-sidebar-image-desc p10-b">$444.00</p>
                        <p></p>
                    </Col>

                <Row className="border-line-bottom">
                    <Col lg={24} md={24} span={24}>
                        <p className="album-sidebar-image-title p10-b">Positions</p>
                        <Row>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('CENTER')}>
                                <BorderVerticleOutlined className={(textPos === 'CENTER') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Center-Center</p>
                            </div>
                            </Col>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('BOTTOM-RIGHT')}>
                                <BorderRightOutlined className={(textPos === 'BOTTOM-RIGHT') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Bottom-Right</p>
                            </div>
                            </Col>
                            <Col lg={8} md={8} span={24}>
                            <div className="spamping-icons"
                                 onClick={(e) => updatePosition('BOTTOM-CENTER')}>
                                <BorderBottomOutlined className={(textPos === 'BOTTOM-CENTER') ? "stamping-pos-border" : ""} />
                                <p className="album-sidebar-image-desc ">Bottom-Center</p>
                            </div>
                            </Col>
                            <Col lg={24} md={24} span={24} className="mb10">
                                <p className="album-sidebar-image-title p10">Input text here</p>
                            <input className="stamping-input"
                                type="text"
                                onChange={(e) => updateTextProperties("content", e.target.value)}
                                placeholder="Enter your text here"
                            />
                            </Col>

                            <Col lg={24} md={24} span={24} className="pb10 bg-gray">
                                <p className="album-sidebar-image-title p10">Select text color</p>
                                <Row>
                                <Col lg={6} md={6} span={24}>
                                    <div className="spamping-colors"
                                         onClick={(e) => updateTextProperties("color", "#ffd700")}>
                                        <div style={{background:"#ffd700"}} className={(color === "#ffd700") ? "stamping-oval stamping-pos-border" : "stamping-oval"}></div>
                                        <p className="album-sidebar-image-desc ">Gold</p>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} span={24}>
                                    <div className="spamping-colors"
                                         onClick={(e) => updateTextProperties("color", "#C0C0C0")}>
                                        <div style={{background:"#C0C0C0"}} className={(color === "#C0C0C0") ? "stamping-oval stamping-pos-border" : "stamping-oval"}></div>
                                        <p className="album-sidebar-image-desc ">Silver</p>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} span={24}>
                                    <div className="spamping-colors"
                                         onClick={(e) => updateTextProperties("color", "#ffffff")}>
                                        <div style={{background:"#ffffff"}} className={(color === "#ffffff") ? "stamping-oval stamping-pos-border" : "stamping-oval"}></div>
                                        <p className="album-sidebar-image-desc ">White</p>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} span={24}>
                                    <div className="spamping-colors"
                                         onClick={(e) => updateTextProperties("color", "#000000")}>
                                        <div style={{background:"#000000"}} className={(color === "#000000") ? "stamping-oval stamping-pos-border" : "stamping-oval"}></div>
                                        <p className="album-sidebar-image-desc ">Black</p>
                                    </div>
                                </Col>
                                </Row>


                            </Col>
                            <p className="album-sidebar-image-title p10-b">Font Size</p>
                            <Col lg={24} md={24} span={24}>
                            <input
                                className="stamping-font-size"
                                type="range"
                                defaultValue="14"
                                min="10"
                                max="48"
                                onChange={(e) => updateTextProperties("fontSize", `${e.target.value}px`)}
                            />
                            </Col>

                            <p className="album-sidebar-image-title p10">Font family</p>
                            <Col lg={24} md={24} span={24}>
                            <Row>
                                {fontClasses.map((fontClass, index) => (
                                    <Col onClick={()=>updateFontFamily(fontClass)} key={index} lg={6} md={6} span={12} className={`${fontClass} stamping-font-family`}>
                                        <p className={(fontFamily ===  fontClass) ? "stamping-font-family-border" : ""}>ABC</p>

                                    </Col>
                                ))}
                            </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Row>
        </>
    );
}

export default AlbumTextSidebar;